/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';
@import "../../scripter-admin/src/scss/modal";

:root {
  // Font Families
  --title-font-family: Montserrat, sans-serif;
  --subtitle-text-font-family: Inter, sans-serif;

  // Font Weights
  --font-weight-bold: 600;
  --font-weight-medium: 550;
  --font-weight-regular: 400;

  // Color Palette

  --border-color-tone: #EFDBD7;

  // Primary
  --primary-color-tone-100: #FFFFFF;
  --primary-color-tone-98: #FFF8F6;
  --primary-color-tone-95: #FFEDEA;
  --primary-color-tone-90: #FFDAD4;
  --primary-color-tone-80: #FFB4A8;
  --primary-color-tone-70: #FF8A77;
  --primary-color-tone-60: #FB5943;
  --primary-color-tone-50: #D8402D;
  --primary-color-tone-40: #B52617;
  --primary-color-tone-35: #A3180C;
  --primary-color-tone-30: #920601;
  --primary-color-tone-25: #7D0300;
  --primary-color-tone-20: #680200;
  --primary-color-tone-10: #410100;
  --primary-color-tone-0: #000000;

  // Secondary
  --secondary-color-tone-100: #FFFFFF;
  --secondary-color-tone-98: #FBF8FF;
  --secondary-color-tone-95: #EFEFFF;
  --secondary-color-tone-90: #DDE1FF;
  --secondary-color-tone-80: #B8C4FF;
  --secondary-color-tone-70: #92A6FF;
  --secondary-color-tone-60: #6B89FF;
  --secondary-color-tone-50: #496CF0;
  --secondary-color-tone-40: #2B51D5;
  --secondary-color-tone-35: #1744C9;
  --secondary-color-tone-30: #0037B9;
  --secondary-color-tone-25: #002E9F;
  --secondary-color-tone-20: #002585;
  --secondary-color-tone-10: #001453;
  --secondary-color-tone-0: #000000;

  // Tertiary
  --tertiary-color-tone-100: #FFFFFF;
  --tertiary-color-tone-98: #FFF8F5;
  --tertiary-color-tone-95: #FFEEE1;
  --tertiary-color-tone-90: #FFDCBE;
  --tertiary-color-tone-80: #FFB871;
  --tertiary-color-tone-70: #EB993F;
  --tertiary-color-tone-60: #CC7F26;
  --tertiary-color-tone-50: #AD6607;
  --tertiary-color-tone-40: #8B5000;
  --tertiary-color-tone-35: #7A4600;
  --tertiary-color-tone-30: #6A3C00;
  --tertiary-color-tone-25: #5A3200;
  --tertiary-color-tone-20: #4A2800;
  --tertiary-color-tone-10: #2D1600;
  --tertiary-color-tone-0: #000000;

  // Error
  --error-color-tone-100: #FFFFFF;
  --error-color-tone-98: #FFF8F7;
  --error-color-tone-95: #FFEDEA;
  --error-color-tone-90: #FFDAD6;
  --error-color-tone-80: #FFB4AB;
  --error-color-tone-70: #FF897D;
  --error-color-tone-60: #FF5449;
  --error-color-tone-50: #DE3730;
  --error-color-tone-40: #BA1A1A;
  --error-color-tone-35: #A80710;
  --error-color-tone-30: #93000A;
  --error-color-tone-25: #7E0007;
  --error-color-tone-20: #690005;
  --error-color-tone-10: #410002;
  --error-color-tone-0: #000000;

  // Neutral
  --neutral-color-tone-100: #FFFFFF;
  --neutral-color-tone-98: #FFF8F6;
  --neutral-color-tone-96: #FFF0EE;
  --neutral-color-tone-95: #FBEEEC;
  --neutral-color-tone-90: #EDE0DD;
  --neutral-color-tone-85: #E1D6D3;
  --neutral-color-tone-80: #D0C4C2;
  --neutral-color-tone-70: #B4A9A7;
  --neutral-color-tone-60: #998E8D;
  --neutral-color-tone-50: #7F7573;
  --neutral-color-tone-40: #655C5B;
  --neutral-color-tone-35: #59504F;
  --neutral-color-tone-30: #4D4544;
  --neutral-color-tone-25: #413A38;
  --neutral-color-tone-20: #362F2E;
  --neutral-color-tone-10: #201A19;
  --neutral-color-tone-0: #000000;
}

// Color Styles



// Main
.primary {
  background-color: var(--primary-color-tone-50);
  color: var(--primary-color-tone-100);
}

.primary-container {
  background-color: var(--primary-color-tone-95);
  color: var(--primary-color-tone-20);
}

.secondary {
  background-color: var(--secondary-color-tone-50);
  color: var(--secondary-color-tone-100);
}

.secondary-container {
  background-color: var(--secondary-color-tone-95);
  color: var(--secondary-color-tone-20);
}

.tertiary {
  background-color: var(--tertiary-color-tone-50);
  color: var(--tertiary-color-tone-100);
}

.tertiary-container {
  background-color: var(--tertiary-color-tone-90);
  color: var(--tertiary-color-tone-20);
}

.error {
  background-color: var(--error-color-tone-50);
  color: var(--error-color-tone-100);
}

.error-container {
  background-color: var(--error-color-tone-90);
  color: var(--error-color-tone-20);
}

.input-error {
  font-size: 14px;
  color: var(--primary-color-tone-35);
}

// Container
.container-lowest {
  background-color: var(--neutral-color-tone-100);
}

.container-low {
  background-color: var(--neutral-color-tone-98);
}

.container {
  background-color: var(--neutral-color-tone-95);
}

.container-high {
  background-color: var(--neutral-color-tone-90);
}

.container-highest {
  background-color: var(--neutral-color-tone-85);
}

.inverse-container {
  background-color: var(--neutral-color-tone-10);
}

// On Container, Outline, On Inverse
.on-container {
  color: var(--neutral-color-tone-10);
}

.on-container-low {
  color: var(--neutral-color-tone-40);
}

.on-container-lowest {
  color: var(--neutral-color-tone-50);
}

.on-container-mute {
  color: var(--neutral-color-tone-70);
}

.outline {
  border: 1px solid var(--neutral-color-tone-10);
}

.outline-low {
  border: 1px solid var(--neutral-color-tone-80);
}

.outline-lowest {
  border: 1px solid var(--neutral-color-tone-90);
}

.on-inverse-container {
  color: var(--neutral-color-tone-95);
}

.on-inverse-primary {
  color: var(--primary-color-tone-80);
}

// Title Bold
.title-bold-h1 {
  font-family: var(--title-font-family), sans-serif;
  font-size: 40px;
  font-weight: var(--font-weight-bold);
  line-height: 1.24;
  letter-spacing: 0;
}

.title-bold-h2 {
  font-family: var(--title-font-family), sans-serif;
  font-size: 32px;
  font-weight: var(--font-weight-bold);
  line-height: 1.28;
  letter-spacing: 0;
}

.title-bold-h3 {
  font-family: var(--title-font-family), sans-serif;
  font-size: 24px;
  font-weight: var(--font-weight-bold);
  line-height: 1.32;
  letter-spacing: 0;
}

.title-bold-h4 {
  font-family: var(--title-font-family), sans-serif;
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  letter-spacing: 0;
}

.title-bold-h5 {
  font-family: var(--title-font-family), sans-serif;
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  letter-spacing: 0;
}

// Title Regular
.title-regular-h1 {
  font-family: var(--title-font-family), sans-serif;
  font-size: 40px;
  font-weight: var(--font-weight-regular);
  line-height: 1.24;
  letter-spacing: 0;
}

.title-regular-h2 {
  font-family: var(--title-font-family), sans-serif;
  font-size: 32px;
  font-weight: var(--font-weight-regular);
  line-height: 1.28;
  letter-spacing: 0;
}

.title-regular-h3 {
  font-family: var(--title-font-family), sans-serif;
  font-size: 24px;
  font-weight: var(--font-weight-regular);
  line-height: 1.32;
  letter-spacing: 0;
}

.title-regular-h4 {
  font-family: var(--title-font-family), sans-serif;
  font-size: 20px;
  font-weight: var(--font-weight-regular);
  line-height: 1.4;
  letter-spacing: 0;
}

// Subtitle
.subtitle-bigger {
  font-family: var(--subtitle-text-font-family), sans-serif;
  font-size: 17px;
  font-weight: var(--font-weight-medium);
  line-height: 1.44;
  letter-spacing: 0;
}

.subtitle-big {
  font-family: var(--subtitle-text-font-family), sans-serif;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  line-height: 1.48;
  letter-spacing: 0;
}

.subtitle-default {
  font-family: var(--subtitle-text-font-family), sans-serif;
  font-size: 15px;
  font-weight: var(--font-weight-medium);
  line-height: 1.48;
  letter-spacing: 0;
}

.subtitle-small {
  font-family: var(--subtitle-text-font-family), sans-serif;
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  line-height: 1.48;
  letter-spacing: 0;
}

.subtitle-smallest {
  font-family: var(--subtitle-text-font-family), sans-serif;
  font-size: 13px;
  font-weight: var(--font-weight-medium);
  line-height: 1.48;
  letter-spacing: 0;
}

// Text
.text-bigger {
  font-family: var(--subtitle-text-font-family), sans-serif;
  font-size: 17px;
  font-weight: var(--font-weight-regular);
  line-height: 1.44;
  letter-spacing: 0;
}

.text-big {
  font-family: var(--subtitle-text-font-family), sans-serif;
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  line-height: 1.48;
  letter-spacing: 0;
}

.text-default {
  font-family: var(--subtitle-text-font-family), sans-serif;
  font-size: 15px;
  font-weight: var(--font-weight-regular);
  line-height: 1.48;
  letter-spacing: 0;
}

.text-small {
  font-family: var(--subtitle-text-font-family), sans-serif;
  font-size: 14px;
  font-weight: var(--font-weight-regular);
  line-height: 1.48;
  letter-spacing: 0;
}

.text-smallest {
  font-family: var(--subtitle-text-font-family), sans-serif;
  font-size: 13px;
  font-weight: var(--font-weight-regular);
  line-height: 1.48;
  letter-spacing: 0;
}

button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

// Styles for app-input component
.area_white_bg {
  background-color: #fff;
  --input-background-color: var(--neutral-color-tone-96);
}

.area_beige_bg {
  background-color: var(--neutral-color-tone-96);
  --input-background-color: #fff;
}

// Styles for filled icons
.material-symbols-filled {
  font-variation-settings:
    'FILL' 1,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

*::-webkit-scrollbar {
  width: 15px; /* Ширина всего элемента навигации */
}

*::-webkit-scrollbar-track {
  background-color: white;
  outline: 1px solid #EFDBD7;
}

*::-webkit-scrollbar-thumb {
  background-color: #EFDBD7;
  border: 5px solid white;
  border-radius: 15px;
}
