.modal-backdrop {
  background-color: black;
  filter: opacity(.64);
  width: 100vw;
  height: 100vh;
}

.app-modal {
  &-container {
    background: #fff;
    max-height: 100%;
    border-radius: 20px;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr auto;

    &__header {
      height: 20px;
      border-bottom: 1px solid #EFDBD7;
      padding: 21px 24px;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      background-color: var(--neutral-color-tone-100);
    }

    &__title {
      margin: 0;
      color: #251917;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__button {
      display: flex;
      align-items: center;
    }

    &__close {
      font-size: 24px;
      color: red;
    }

    &__form {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
    }

    &__body {
      flex: 1; /* Занимает оставшееся пространство формы */
      margin: 0;
      padding: 24px;
      padding-top: 0;
      padding-bottom: 0;
      overflow-y: auto; /* Скролл только по вертикали */
      overflow-x: hidden; /* Скрытие горизонтального скролла */
    }

    &__buttons {
      padding: 24px;
      border-top: 1px solid #EFDBD7;
      display: flex;
      justify-content: space-around;
      gap: 16px;
      background-color: var(--neutral-color-tone-100);
    }

    .modal-button {
      min-width: 208px;
      width: 80%;
      height: 40px;
      border-radius: 12px;
      transition: opacity .3s ease;

      &:hover {
        opacity: .8;
      }
    }

    &__cancel {
      color: var(--primary-color-tone-50);
    }

    &__submit {
      background-color: var(--primary-color-tone-50);
      color: var(--primary-color-tone-100);
    }
  }
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.login-input {
  margin: 15px 0 35px;
}
